.body {
    width: 60%;
    /* Ensure the container spans the full width of its parent */
    // padding-top: 75%; /* 4:3 aspect ratio (75% = 3/4) */
    // position: relative; /* Create a relative positioning context for child elements */
    position: absolute;
    top: 0;
    left: 20%;
    /* Add left padding for narrower screens */
    // right: 10%; /* Add right padding for narrower screens */
    bottom: 0;
    font-family: sans-serif;
}

.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #ffffff;
    height: 75px;
    /* 4:3 aspect ratio (75px = 3/4 * 100px) */
    font-family: sans-serif;
}

.navbar-name {
    display: flex;
    justify-content: flex-end;
    /* Align items to the left */
    align-items: center;
}

.navbar-elements {
    display: flex;
    list-style: none;
    margin: 0;
    padding: 0;
    margin-right: 20px;
    /* Adjust spacing between navbar elements */
}


.navbar a {
    text-decoration: none;
    color: #000000;
    /* Text color for navbar elements */
    font-size: 16px;
    font-weight: bold;
    padding: 10px 15px;
    /* Adjust padding for height and width */
    background-color: #ffffff;
    /* Background color for navbar elements */
    border-radius: 4px;
    transition: color 0.3s ease;
}

.navbar a:hover {
    color: #db3b3b;
    /* Hover background color */
}

.about {
    display: flex;
    justify-content: space-between;
    align-items: center;
    // align-items: center;
    background-color: #ffffff;
    // height: 75px;
    /* 4:3 aspect ratio (75px = 3/4 * 100px) */
}

.about-profile-img {
    // max-height: 40%;
    /* Set the maximum width of the image to 20% of the container */
    height: auto;
    width: auto;
    /* Maintain the aspect ratio */
    display: block;
    /* Remove any extra spacing around the image */
    float: right;
    // margin: 0 auto; /* Center the image horizontally within the container */
    max-height: 500px;
}

.separator {
    border-left: 1px solid #000000; /* Vertical line with 1px width and gray color */
    height: 100%; /* Adjust the height as needed */
    margin: 0 20px; /* Add margin to control spacing */
}
